<template>
	<pui-datatable :modelName="model" :showRowActions="true" :defaultActionRead="true"></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'paprogramaambiental-grid',
	data() {
		return {
			model: 'paprogramaambiental'
		};
	},

	methods: {}
};
</script>

<style lang="postcss" scoped>
</style>
